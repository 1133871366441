.ageGate{
    padding-top: 374px;
    max-width: 933px;
    width: 100%;
    display: flex;
    flex-direction: column;
    font-stretch: condensed;
}
.ageGate .gateTitle{
    border-radius: 20px;
    background-color: rgb(213, 12, 20);
    font-size: 54.6px;
    color: rgb(255, 255, 255);
    font-weight: 600;
    font-stretch: condensed;
    font-family: "Myriad Pro";
    display: flex;
    align-items: center;
    padding-left: 48px;
    padding-top: 5px;
    text-align: left;
    margin-bottom: 29px;
    box-sizing: border-box;
    height: 96px;
}

.ageGate .gateInputs{
    display: flex;
    gap: 27px;
    justify-content: space-between;
    margin-bottom: 167px;
}
.ageGate .gateInputs input{
    width: 100%;
    border-radius: 20px;
    background-color: rgb(255, 255, 255);
    box-shadow: inset 0px 2px 2px 0px rgba(143, 143, 143, 0.004);
    height: 96px;
    display: flex;
    align-items: center;
    padding-left: 50px;
    font-size: 32.3px;
    font-family: "Myriad Pro";
    font-weight: 300;
    color: rgb(137, 137, 137);
}

.ageGate .gateInputs input:focus{
    outline: none;
}

.ageGate .gateButton{
    width: 281px;
    height: 96px;
    border-radius: 20px;
    font-size: 61px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Myriad Pro";
    font-weight: 600;
    margin-left: auto;
    margin-right: auto;
    background-color: rgb(255, 255, 255);
    color: #c9080f;
}

.ageGate .gateButton:hover{
    cursor: pointer;
}


@media (max-width: 1750px) {
    .ageGate{
        max-width: 700px;
    }
    .ageGate .gateInputs input{
        height: 70px;
    }
    .ageGate .gateTitle{
        height: 74px;
    }
    .ageGate .gateButton{
        height: 70px;
    }
    .ageGate .gateInputs{
        margin-bottom: 70px;
    }
}

@media (max-width: 768px) {
    .ageGate{
        max-width: 400px;
        padding-top: 110px;
    }
    .ageGate .gateTitle{
        font-size: 60px;
        padding: 25px 40px 24px 35px;
        align-items: center;
        justify-content: center;
        height: auto;
        text-align: center;
        max-height: 227px;
    }
    .ageGate .gateInputs{
        flex-direction: column;
        margin-bottom: 87px;
    }
    .ageGate .gateInputs input{
        box-sizing: border-box;
        padding-left: 83px;
        font-size: 43px;
    }
}

@media (max-width: 600px) {
    .ageGate{
        max-width: 275px;
      
    }
    .ageGate .gateTitle{
        font-size: 40px;
        padding: 0 40px 0 45px;
        max-height: 160px;
    }
    .ageGate .gateInputs{
        margin-bottom: 47px;
    }
    .ageGate .gateInputs input{
        font-size: 33px;
        height: 60px;
    }
    .ageGate .gateButton{
        height: 60px;
        border-radius: 20px;
        font-size: 40px;
    }
}